<template>
  <div style="width: 100%;">
    <div class="row" v-if="showBalance">
      <h6 class="col-lg-4 col-md-12 col-sm-12 col-xs-12">{{ $t('credit_overview.total_bought') }}:
        <credits-view v-bind:amount="totalBought" :country_id="countryId"/>
      </h6>
      <h6 class="col-lg-4 col-md-12 col-sm-12 col-xs-12">{{ $t('credit_overview.total_used') }}:
        <credits-view v-bind:amount="totalUsed" :country_id="countryId"/>
      </h6>
      <h6 class="col-lg-4 col-md-12 col-sm-12 col-xs-12">{{ $t('credit_overview.total_remaining') }}:
        <credits-view v-bind:amount="(totalRemaining)" :country_id="countryId"/>
      </h6>
    </div>
    <div class="dataTables_wrapper VueTables no-shadow table-responsive">
      <b-table class="table table-bordered dataTable withColumn" :items="tableData" :fields="columns">

        <template #cell(VIN)="{item}">
          <span v-if="item.object">{{item.object.VIN.toUpperCase()}}</span>
        </template>

        <template #cell(object.createdAt)="{item}">
          {{ (item.object ? (item.object.createdAt) : '') | localeDate }}
        </template>

        <template #head(amount)>
          Received <img v-if="!showInLocal" :src="$store.getters['geo/currencyCreditSymbol']" class="symbol">
        </template>
        <template #cell(amount)="{item}">
          <credits-view show-lines-zero :style="`color:${getColor(item.amount)}; font-weight: bold; `"
                        :amount="item.amount" :currency-details="item.currencyDetails" :no-show-logo="!showInLocal"
                        symbol-to-end/>
        </template>

        <template #head(used)>
          Used <img v-if="!showInLocal" :src="$store.getters['geo/currencyCreditSymbol']" class="symbol">
        </template>
        <template #cell(used)="{item}">
          <credits-view show-lines-zero :style="`color:${getColor(item.used)}; font-weight: bold; `"
                        :amount="item.used"
                        :currency-details="item.currencyDetails"
                        :no-show-logo="!showInLocal"
                        symbol-to-end/>
        </template>

        <template #head(remaining)>
          Remaining <img v-if="!showInLocal" :src="$store.getters['geo/currencyCreditSymbol']" class="symbol">
        </template>
        <template #cell(remaining)="{item}">
          <credits-view :style="`font-weight: bold; `"
                        :amount="item.remaining"
                        :currency-details="item.currencyDetails"
                        :no-show-logo="!showInLocal"
                        symbol-to-end/>
        </template>


        <template #cell(euro)="{item}">
          <currency-price-view :amount="item.euro" :currency-details="item.currencyDetails"/>
        </template>

        <template #cell(createdAt)="{item}">
          {{ item.createdAt | localDateShort }}
        </template>

        <template #cell(model)="{item}">
          {{ item.model | overviewCategory(item.movement) }}
        </template>

        <template #cell(object.vehicle.name)="{item}">
          <div v-if="item.object != null">
            {{ item.object.vehicle.brand || item.object.vehicle.name }} / {{ item.object.vehicle.model }}
          </div>
        </template>

        <template #cell(object.stage)="{item}">
          <span v-if="item.object != null">
            {{ item.object.stage|stageType(item.object.type) }}
          </span>
        </template>

        <template #cell(factor)="{item}">
          {{
            Number(100 - ((item.euro * 100) / item.amount))
              .toFixed(0)
          }}%
        </template>
      </b-table>
    </div>
    <b-pagination
      class="justify-content-end no-shadow p-2"
      v-model="pagination.currentPage"
      :total-rows="pagination.total"
      :per-page="pagination.perPage"
      @change="paginate"
    />
  </div>
</template>

<script>
import {CREDITS_COLORS} from "@/constants";
import {decrypt} from "@/helpers/encrypt";
import {get} from "@/services/api";
import {Pagination} from "@/utils/pagination";
import {mapGetters, mapState} from "vuex";
import CreditsView from '@/components/common/CreditsView'
import CurrencyPriceView from "@/components/common/CurrencyPriceView";

export default {
  name: "CreditOverviewTable",
  components: {
    CurrencyPriceView,
    CreditsView,
  },
  data: () => ({
    columns: [
      {
        key: 'description',
        label: 'Description',
        tdClass: 'withColumn'
      },
      {
        key: 'model',
        label: 'Category',
        tdClass: 'withColumn'
      },
      {
        key: 'type',
        label: 'Type',
        tdClass: 'withColumn'
      },
      {
        key: 'movement',
        label: 'Movement',
        tdClass: 'withColumn'
      },
      {
        key: 'VIN',
        label: 'VIN',
        tdClass: 'withColumn'
      },
      {
        key: 'object.vehicle.name',
        label: 'Vehicle',
        tdClass: 'withColumn'
      },
      {
        key: 'object.descriptionObject.description.client.name',
        label: 'Customer',
        tdClass: 'withColumn'
      },
      {
        key: 'object.type',
        label: 'Type',
        tdClass: 'withColumn'
      },
      {
        key: 'object.vehicle.engine_family',
        label: 'ECU family',
        tdClass: 'withColumn'
      },
      {
        key: 'object.stage',
        label: 'Stage',
        tdClass: 'withColumn'
      },
      {
        key: 'object.vehicle.dsg',
        label: 'TCU',
        tdClass: 'withColumn'
      },
      {
        key: 'amount',
        class: 'numbers',
        label: 'Received',
        tdClass: 'withColumn text-right'
      },
      {
        key: 'used',
        class: 'numbers',
        label: 'Used',
        tdClass: 'withColumn text-right'
      },
      {
        key: 'remaining',
        class: 'numbers',
        label: 'Remaining',
        tdClass: 'withColumn text-right'
      },
      {
        key: 'createdAt',
        label: 'Date',
        tdClass: 'withColumn'
      }
    ],
    tableData: [],
    userBalance: 0,
    pagination: new Pagination(),
    totalBought: 0,
    totalUsed: 0,
    totalTransferred: 0,
    totalRemaining: 0
  }),
  props: {
    userId: {
      type: Number,
      default: null
    },
    showBalance: {
      type: Boolean,
      default: false
    },
    showAllBalance: {
      type: Boolean,
      default: false
    },
    countryId: {
      type: Number,
      default: false
    }
  },
  watch: {
    userId: {
      immediate: true,
      handler(e) {
        if (e !== null && e !== undefined)
          this.getData();
      }
    },
  },

  computed: {
    ...mapState('buySale', ['information']),
    ...mapGetters('geo', ['currencyCreditSymbol', 'creditsToLocalCredits', 'currencyToUser']),
    ...mapState('geo', ['showInLocal'])
  },
  methods: {
    async getData() {
      let id = localStorage.getItem('admin-id');
      id = decrypt(id);
      get(`credit-movement?page=${this.pagination.currentPage}&limit=${this.pagination.perPage}&id=${this.userId}&query=null`, null, true)
        .then(({data}) => {
          this.$emit('on-load', data)
          this.totalBought = data.bought;
          this.totalUsed = data.used;
          this.totalTransferred = data.transferred;
          this.totalRemaining = Number(this.totalBought) + Number(this.totalUsed);
          this.tableData = data.data.map(row => {
            let type = '';
            if (row.type !== null) {
              type = this.getType(row.type, row.userId, id)
            }
            if (row.amount !== null) {
              if (row.amount > 0) {
                row.used = 0
              } else {
                row.used = row.amount
                row.amount = 0;
              }
            }
            return {
              ...row,
              type,
              object: row.softwareOrder ? {
                ...row.softwareOrder,
                vehicle: (row.softwareOrder !== null) ? JSON.parse(row.softwareOrder.vehicle) : '',
              } : null,
              // _rowVariant: row.amount > 0 ? '' : 'danger',
            };
          });
          this.pagination.total = data.count;
        });
    },
    paginate(e) {
      this.pagination.currentPage = e;
      this.getData();
    },
    getColor(amount) {
      return (Number(amount) > 0) ? CREDITS_COLORS.positive : (Number(amount) < 0) ? CREDITS_COLORS.negative : CREDITS_COLORS.zero
    },
    getType(data, userId, id) {
      const parse = JSON.parse(data);
      if (this.information.rol.includes('administrator') || this.information.rol.includes('support')) {
        return parse.admin;
      } else if (this.information.rol.includes('distributor')) {
        return (Number(userId) === Number(id)) ? parse.distributor : parse.dealer;
      } else if (this.information.rol.includes('dealer') || this.information.rol.includes('subdealer')) {
        return parse.dealer;
      }
    },
  }
}
</script>

<style scoped>

</style>
