<template>
  <div class="dataTables_wrapper VueTables no-shadow table-responsive">
    <b-table small bordered hover class="table table-bordered dataTable withColumn" :items="arrayData" :fields="columns">
      <template #cell(object.createdAt)="{item}">
        {{ (item.object ? (item.object.createdAt) : '') | localeDate }}
      </template>
      <template #head(bought)>
        Received <img v-if="!showInLocal" :src="$store.getters['geo/currencyCreditSymbol']" class="symbol">
      </template>
      <template #cell(bought)="{item}">
        <credits-view :amount="item.bought" :no-show-logo="!showInLocal" symbol-to-end
                      :country_id="item.countryId"/>
      </template>
      <template #cell(company.country)="{item}">
        {{ $store.getters["geo/country"](item.countryId).alpha3Code }}
      </template>
      <template #head(used)>
        Used <img v-if="!showInLocal" :src="$store.getters['geo/currencyCreditSymbol']" class="symbol">
      </template>
      <template #cell(used)="{item}">
        <credits-view :amount="item.used" :no-show-logo="!showInLocal" symbol-to-end :country_id="item.countryId"/>
      </template>
      <template #head(balance)>
        Remaining <img v-if="!showInLocal" :src="$store.getters['geo/currencyCreditSymbol']" class="symbol">
      </template>
      <template #cell(balance)="{item}">
        <credits-view :amount="item.balance" :no-show-logo="!showInLocal" symbol-to-end
                      :country_id="item.countryId"/>
      </template>
      <template #cell(type)="{item}">
        {{ item.type.join(',') }}
      </template>
    </b-table>
  </div>
</template>

<script>
import CreditsView from '@/components/common/CreditsView';
import {mapState} from "vuex";

export default {
  name: "SubdealerAccount",
  props: {
    arrayData: {
      default: []
    }
  },
  components: {
    CreditsView
  },

  data: () => ({
    showInLocal: false,
    columns: [
      {
        key: 'fullName',
        label: 'Company name'
      },
      {
        key: 'email',
        label: 'Email'
      },
      {
        key: 'company.country',
        label: 'Company country'
      },
      {
        key: 'bought',
        label: 'Received',
        class: 'text-right'
      },
      {key: 'used', class: 'text-right'},
      {key: 'balance', class: 'text-right'},
    ],
  }),
  computed: {
    ...mapState('geo', ['showInLocal', 'tvsCurrency', 'userCurrency']),
  }
}
</script>

<style scoped>

</style>
