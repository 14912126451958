import Vue from 'vue';
import '@/assets/scss/vue-tables.scss';
import {get, post, postBuffer} from '@/services/api';
import {credit, creditDeduced, creditTransfer} from '../../../services/endpoint.json';
import {decrypt, encrypt} from '@/helpers/encrypt';
import SendOfferCredits from '@/views/dashboard/credit/SendOfertCredits';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import {saveAs} from 'file-saver';
import CreditsView from '@/components/common/CreditsView';
import CreditOverviewTable from '@/components/CreditOverviewTable';
import CurrencyPriceView from '@/components/common/CurrencyPriceView';
import AddCreditsWithInvoice from "@/views/dashboard/credit/AddCreditsWithInvoice";
import SubdealerAccount from "@/views/dashboard/credit/SubdealerAccount";
import show_alert_mixin from "@/utils/show_alert_mixin";


export default Vue.extend({
  name: 'DealersSubdealers',
  components: {
    SendOfferCredits,
    CreditsView,
    CurrencyPriceView,
    CreditOverviewTable,
    AddCreditsWithInvoice,
    SubdealerAccount
  },
  mixins: [show_alert_mixin],
  data: () => ({
    filter: '',
    filter_parent: null,
    filterRol: null,
    fieldList: [
      {
        key: 'fullName',
        label: 'Company name',
        sortable: true
      },
      {
        key: 'email',
        label: 'Email',
        sortable: true
      },
      {
        key: 'company.country',
        label: 'Company country',
        sortable: true
      },
      {
        key: 'bought',
        label: 'Received',
        class: 'text-right',
        sortable: true
      },
      {key: 'used', class: 'text-right',sortable: true},
      {key: 'balance', class: 'text-right',sortable: true},
      {
        key: 'type',
        label: 'User Role',
        sortable: true
      },
      {
        key: 'actions',
        label: 'Actions'
      }
    ],
    pagination: {
      current: 1,
      perPage: 25,
      total: null
    },
    userResume: {
      bought: 0,
      used: 0,
      transferred: 0
    },
    fields: [
      // {key: 'user.fullName', label: 'user', tdClass: 'withColumn'},
      {
        key: 'type',
        label: 'Movement',
        tdClass: 'withColumn'
      },
      {
        key: 'description',
        label: 'Description',
        tdClass: 'withColumn'
      },
      {
        key: 'object.VIN',
        label: 'VIN',
        tdClass: 'withColumn'
      },
      {
        key: 'object.vehicle.brand',
        label: 'Vehicle',
        tdClass: 'withColumn text-left'
      },
      {
        key: 'object.descriptionObject.description.client.name',
        label: 'Customer',
        tdClass: 'withColumn'
      },
      {
        key: 'object.type',
        label: 'Type',
        tdClass: 'withColumn'
      },
      {
        key: 'object.vehicle.engine_family',
        label: 'ECU family',
        tdClass: 'withColumn'
      },
      {
        key: 'object.descriptionObject.stage',
        label: 'Stage',
        tdClass: 'withColumn'
      },
      {
        key: 'object.vehicle.dsg',
        label: 'TCU',
        tdClass: 'withColumn'
      }, //FIX THIS
      {
        key: 'amount',
        label: 'Credits',
        tdClass: 'withColumn text-right'
      },
      {
        key: 'object.descriptionObject.description.client.extradata',
        label: 'Notes',
        tdClass: 'withColumn overview-column-note'
      },
      {
        key: 'createdAt',
        label: 'Date',
        tdClass: 'withColumn'
      }
    ],
    tableData: [],
    tableDataSubDealer: [],
    userSelected: null,
    dataHistory: [],
    creditBought: 0,
    creditUsed: 0,
    userName: '',
    rol: '',
    amountCreditTransfer: null,
    amountCreditDeduced: null,
    descriptionCreditTransfer: '',
    descriptionCreditDeduced: '',
    priceListConfiguration: [],
    canTransferDeduct: true,
    haveParent: false,
    userMovenments: [],
    useUserCurrency: false,
    canShowBtnSendOffer: false,
    canShowBtnSendTransfer: false,
    canShowBtnDeducted: false,
    arrayComboUsers: [],
    filterByUserId: {
      id: null,
      username: null,
      countryId: null,
      bought: 0,
      used: 0,
      remaining: 0
    },
    showSubDealerAccount: false
  }),
  mounted() {
    this.getAllUser().then();
    this.checkPermissions()
  },
  computed: {
    ...mapState('buySale', ['information']),
    ...mapState('geo', ['showInLocal', 'tvsCurrency', 'userCurrency']),
    ...mapGetters('geo', ['currencyCreditSymbol', 'creditsToLocalCredits', 'localCreditsToTvsCredits', 'getCurrencyByCountry', 'numberFilter']),
    currencyForExchange: function () {
      let currency = this.tvsCurrency;
      if (this.userSelected && this.useUserCurrency) {
        currency = this.getCurrencyByCountry(this.userSelected.countryId)
      } else if (this.$userCan(['distributor', 'dealer', 'subdealer']) && this.showInLocal) {
        currency = this.userCurrency;
      }
      return currency;
    },
    symbol() {
      const code = this.currencyForExchange.code.toLowerCase();
      try {
        return require(`@/assets/images/credits/${code}.svg`);
      } catch (e) {
        return require(`@/assets/images/credits/default.svg`);
      }
    },
    isDifferentCurrency() {
      if (this.userSelected) {
        const currencies = ['EUR'].concat(this.userCurrency.code);
        const clientCurrency = this.getCurrencyByCountry(this.userSelected.countryId)
        return !currencies.includes(clientCurrency.code)
      }
      return false;
    }
  },
  methods: {
    ...mapMutations(['loaderManager']),
    ...mapActions('buySale', ['loadInformation']),
    async getAllUser() {
      this.loaderManager(true);
      let {data} = await get(`credit/index`, null, true);
      if (Number(data.statusCode) === Number(200)) {
        this.tableData = data.message;
        this.arrayComboUsers = data.message.map(item => {
          return {id: item.id, username: item.fullName, countryId: item.countryId}
        }).sort((a, b) => {
          if (a.username < b.username) {
            return -1;
          }
          if (a.username > b.username) {
            return 1;
          }
          return 0;
        })
        if (this.userSelected) {
          this.userSelected = this.tableData.find(el => el.id == this.userSelected.id)
        }
        this.loaderManager(false);
      } else {
        this.loaderManager(false);
      }
    },
    async getPriceList(id) {
      this.$store.state.priceList.userId = id;
      await this.$store.dispatch('priceList/loadInformationPriceListByUser').then();
      this.priceListConfiguration = this.$store.state.priceList.priceListByUser;
    },
    view(id) {
      this.userSelected = this.tableData.find(item => item.id === id);
      this.haveParent = (this.userSelected.network.parent !== null)
      this.getPriceList(id).then();
    },
    handleClose() {
      this.haveParent = false
      this.userSelected = null;
    },
    transferCredit() {
      if (Number(this.amountCreditTransfer) > 0) {
        const credits = this.localCreditsToTvsCredits(this.amountCreditTransfer, this.currencyForExchange);
        if (credits && this.information.balance >= credits) {
          this.$swal({
            html: 'Do you want to transfer ' + `<img src="${this.symbol}" class="symbol" alt="symbol"/>`
              + this.numberFilter(this.amountCreditTransfer) + ' credit(s) to ' + this.userSelected.fullName + '?',
              // + this.numberFilter(this.currencyForExchange) + ' credit(s) to ' + this.userSelected.fullName + '?',
            showCancelButton: true,
            cancelButtonText: `Cancel`,
            confirmButtonText: `OK`,
            confirmButtonColor: '#d14343',
            cancelButtonColor: '#000000',
          }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  const totalTransfer = (credits > this.information.balance ? this.information.balance : credits)
                  let {data} = await post(creditTransfer, {
                    userIdTo: encrypt(this.userSelected.id),
                    amount: encrypt(totalTransfer),
                    description: this.descriptionCreditTransfer
                  }, true);
                  if (Number(data.statusCode) === Number(200)) {
                    await this.loadInformation();
                    await this.$refs.table.getData();
                    await this.getAllUser(true);
                    this.clearForm()
                    this.showAlertNotification("Success")
                  }
                } catch (e) {
                  this.showAlertNotification('You do not have enough credit balance', 'error')
                }
              }
            });
        } else {
          this.showAlertNotification('You do not have enough credit balance or the amount must be greater than zero ', 'error')
        }
      } else {
        this.showAlertNotification('All fields are required ', 'error')
      }
    },
    deducedCredit() {
      if (Number(this.amountCreditDeduced) > 0) {
        const amount = this.localCreditsToTvsCredits(this.amountCreditDeduced, this.currencyForExchange);
        if ((amount > 0) && this.userSelected.balance > 0) {
          if (Math.round(this.userSelected.balance) >= amount) {
            this.$swal({
              html: 'Do you want to deduce ' + `<br><img src="${this.symbol}" class="symbol" alt="symbol"/>`
                + this.numberFilter(this.amountCreditDeduced) + ' credit(s) from ' + this.userSelected.fullName + '?',
              showCancelButton: true,
              cancelButtonText: `Cancel`,
              confirmButtonText: `OK`,
              confirmButtonColor: '#d14343',
              cancelButtonColor: '#000000',
            }).then(async (result) => {
              if (result.isConfirmed) {
                const typeT = localStorage.getItem('admin-type');
                let type = '';
                let storageT = decrypt(typeT);
                storageT = JSON.parse(storageT);
                storageT.forEach((item) => {
                  type = item;
                });
                type = encrypt(type);
                const totalDeduct = (amount > this.userSelected.balance ? this.userSelected.balance : amount)
                let {data} = await post(creditDeduced, {
                  userIdTo: encrypt(this.userSelected.id),
                  amount: encrypt(totalDeduct),
                  description: this.descriptionCreditDeduced
                }, true);
                if (Number(data.statusCode) === Number(200)) {
                  await this.loadInformation();
                  await this.getAllUser(true);
                  await this.$refs.table.getData();
                  this.userSelected.history = data.message.history;
                  this.userSelected.balance = data.message.balance;
                  this.clearForm()
                  this.showAlertNotification('Success')
                } else {
                  this.showAlertNotification(this.$t('credit.no_fouds', 'error'))
                }
              }
            });
          } else {
            this.showAlertNotification('the user does not have enough credit balance or the amount must be greater than zero ', 'error')
          }
        } else {
          this.showAlertNotification('the user does not have enough credit balance or the amount must be greater than zero ', 'error')
        }
      } else {
        this.showAlertNotification('All fields are required ', 'error')
      }
    },
    async openSendOffer() {
      if (this.information.canSale && this.information.balance > 0) {
        this.$refs.modal.open(this.userSelected.id, this.userSelected.fullName, this.userSelected.email);
      } else {
        let msg = '';
        let url = '';
        if (this.information.balance === 0) {
          msg = 'You do not have credit balance.';
          url = 'order-credit'
        }

        this.$swal({
          title: 'Information',
          html: msg,
          icon: 'info',
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonColor: "#d14343",
          cancelButtonColor: "#000000",
          confirmButtonText: "OK",
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.redirectConfiguration(url).then().catch()
          }
        });
      }
    },
    async selectOptionDownload(type) {
      if (type.toString() === 'excel') {
        await this.downloadExcelCreditOverview(true);
      } else {
        await this.downloadPdf();
      }
    },
    async downloadExcelCreditOverview(export_excel = false) {
      this.loaderManager(true);
      this.readInputStatus = !this.readInputStatus;
      if (this.readInputStatus) {
        get(`credit-movement/export-overview-excel?page=${this.pagination.current}&limit=${this.pagination.perPage}&id=${this.userSelected ? this.userSelected.id : null}&all_balance=true&export_excel=${export_excel}`,
          null, true, 'arraybuffer')
          .then(async ({data}) => {
            const fileURL = window.URL.createObjectURL(new Blob([data]));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'credits_overview.xlsx');
            document.body.appendChild(fileLink);
            fileLink.click();
            this.loaderManager(false);
          })
          .catch(() => {
            this.loaderManager(false);
          });
      }
    },
    downloadExcelAccountsOverview() {
      get(`credit/export-account-excel`,
        null, true, 'arraybuffer')
        .then(async ({data}) => {
          const fileURL = window.URL.createObjectURL(new Blob([data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'accounts_overview.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loaderManager(false);
        })
        .catch(() => {
          this.loaderManager(false);
        });
    },
    async downloadPdf() {
      this.loaderManager(true);
      postBuffer(`credit-movement/export-overview-pdf`,
        {
          allBalance: false,
          id: this.userSelected.id
        },
        true)
        .then(async ({data}) => {
          const fileName = `credit-overview- ${new Date().getTime()}.pdf`;
          const file2 = new File([data], fileName, {type: 'application/pdf'});
          saveAs(file2);
          this.loaderManager(false);
          location.reload()
        })
        .catch(e => {
          this.loaderManager(false);
        });
    },
    clearForm() {
      this.descriptionCreditDeduced = '';
      this.amountCreditDeduced = null;
      this.descriptionCreditTransfer = '';
      this.amountCreditTransfer = null;
    },
    async redirectConfiguration(url) {
      this.$router.push({name: url}).then().catch();
    },
    async filterByRol() {
      this.filterByUserId.id = null
      await this.getAllUser()
      if (!!this.filterRol) {
        const tempArray = JSON.parse(JSON.stringify(this.tableData))
        this.tableData = tempArray.filter(x => x.type.toString() === this.filterRol.toString())
      }
    },
    checkPermissions() {
      if (this.$userCan(['distributor', 'dealer'])) {
        if (this.$store.state.user.subUser) {
          this.canShowBtnSendOffer = (this.$store.state.user.subUser && this.$checkSubUserPermission('credits.sale_credits.send_offer'))
          this.canShowBtnSendTransfer = (this.$store.state.user.subUser && this.$checkSubUserPermission('credits.sale_credits.transfer_credits'))
          this.canShowBtnDeducted = (this.$store.state.user.subUser && this.$checkSubUserPermission('credits.sale_credits.deduct_credits'))
        } else {
          this.canShowBtnSendOffer = this.information.canSale && this.$userPermission(false, 'sale', 'send_offer')
          this.canShowBtnSendTransfer = this.$userPermission(false, 'sale', 'transfer_credits')
          this.canShowBtnDeducted = this.$userPermission(false, 'sale', 'deduct_credits')
        }
      } else if (this.$userCan(['administrator'])) {
        this.canShowBtnSendOffer = true
        this.canShowBtnSendTransfer = true
        this.canShowBtnDeducted = true
      }
    },
    async filterByUser() {
      this.filterRol = null
      await this.getAllUser()
      if (!!this.filterByUserId && !!this.filterByUserId.id) {
        const tempArray = JSON.parse(JSON.stringify(this.tableData))
        this.tableData = tempArray.filter(x => x.id.toString() === this.filterByUserId.id.toString())
        this.tableData.forEach(item => {
          this.filterByUserId.username = item.fullName
          this.filterByUserId.bought = item.bought
          this.filterByUserId.used = item.used
          this.filterByUserId.remaining = item.bought + item.used
        })
        if (this.$userCan(['administrator', 'support'])) {
          this.getDataSubDealerByParent()
        }
      } else {
        this.filterByUserId.username = null
        this.filterByUserId.bought = null
        this.filterByUserId.used = null
        this.filterByUserId.remaining = null
      }
    },
    async getDataSubDealerByParent() {
      const {data} = await get(`credit/index-parent?id=${this.filterByUserId.id}`, null, true)
      this.tableDataSubDealer = data.message;
      this.showSubDealerAccount = data.message.length > 0
    }
  },
});

