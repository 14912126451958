<template>
  <b-modal v-model="active" title="Add credits and generate invoice" size="lg" body-class="px-3" hide-footer>
    <b-form-group :disabled="isManual" class="px-4">
      <label> Select Price List</label>
      <select class="mb-3 form-control" v-model="selected">
        <option :value="null">Select</option>
        <option :value="item._id" v-for="(item, index) in priceList" :key="index"> {{ item.name }}</option>
      </select>
    </b-form-group>
    <b-form-group>
      <div class="d-flex">
        <div class="col-lg-6 col-md-6">
          <b-form-checkbox v-model="isManual">Config Manual</b-form-checkbox>
        </div>
        <div class="col-lg-6 col-md-6">
          <b-form-checkbox checked="true" v-model="isAllCredits" name="check-button" switch><b> Credits fully paid?
            {{ isAllCredits ? 'YES' : 'NO' }} </b></b-form-checkbox>
        </div>
      </div>
    </b-form-group>

    <order-credit v-if="!isManual" class="px-0" :as-sub-component="true" :to-user="userClientId"
                  :restartValueForm="restartValueForm" @recalculateOrder="recalculateOrder"/>
    <manual-credit v-if="isManual" v-bind:is-all-credits="isAllCredits" @recalculateOrder="recalculateOrder"/>

    <b-form-group>
      <div class="row" v-if="!isAllCredits">
        <div class="col"></div>
        <div class="col">
          <label>Enter Purchase price (price paid) </label>
          <b-form-input class="mb-2" id="input-discount" v-model.number="model.priceToAdd" type="number"
                        placeholder="Enter discount" required></b-form-input>
          <label>Credit amount (total credits to add): </label>
          <b-form-input class="mb-2" id="input-discount" v-model.number="model.creditsToAdd" type="number"
                        placeholder="Enter discount" required></b-form-input>
        </div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col"></div>
        <div class="col">
          <b-button :disabled="disabledButton" variant="primary btn-block" @click="placeOrder">Place order</b-button>
        </div>
        <div class="col"></div>
      </div>
    </b-form-group>


  </b-modal>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import OrderCredit from "@/views/dashboard/credit/order/index.vue";
import ManualCredit from "./ManualCreditsWithInvoice";
import {post} from "@/services/api";
import {creditOrderFinalConfirmationModal, creditOrderFinalConfirmationModalSection2} from "@/constants";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "AddCreditsWithInvoice",
  components: {OrderCredit, ManualCredit},
  mixins: [show_alert_mixin],
  props: {
    userClientId: {
      required: true
    }
  },
  data: () => ({
    active: false,
    selected: null,
    isManual: false,
    isAllCredits: true,
    disabledButton: false,
    currencyForExchange: null,
    model: {
      creditsToAdd: 0,
      priceToAdd: 0
    },
    restartValueForm: false
  }),
  watch: {
    currentList: function () {
      this.$store.commit('priceList/setInformationPriceList', this.currentList.priceList)
    },
    isAllCredits: function (e) {
      this.restartValueForm = e
      if (e) {
        this.model.creditsToAdd = 0;
        this.model.priceToAdd = 0;
        this.model.price = 0;
        this.model.discount = 0;
      }
    }
  },
  computed: {
    ...mapGetters('geo', ['currencyToUser', 'creditsToLocalCredits', 'currencyCreditSymbol', 'localCreditsToTvsCredits',
      'formatToCurrency', 'getCurrentCurrencyUsed', 'tvsCreditsToLocalCredits', 'numberFilter']),
    ...mapState('geo', ['tvsCurrency', 'userCurrency', 'showInLocal']),
    ...mapState('priceList', {
      priceConfig: state => state.priceListByUser
    }),
    priceList() {
      return this.priceConfig.list;
    },
    currentList() {
      if (!this.selected)
        return this.priceConfig.assigned;
      return this.priceList.find(el => el._id === this.selected)
    }
  },
  mounted() {
    this.currencyForExchange = this.$store.state.geo.userCurrency;
  },
  methods: {
    ...mapMutations(['loaderManager', 'userNameManager']),
    open() {
      this.active = true;
    },
    recalculateOrder(e) {
      Object.assign(this.model, e)
      this.model.creditsToAdd = e.credit
      this.model.priceToAdd = e.price
      this.disabledButton = (this.model.discount > 65)
    },
    async placeOrder() {
      this.disabledButton = true;
      if (this.validateData()) {
        let section = '\n' +
          '<div>\n' +
          '<div class="row">' +
          '<div class="text-center col">\n' +
          '<h4 style="color: black">FINAL CONFIRMATION</h4>';
        if (!this.priceList.length) {
          section += '<h6 class="text-danger">' + (this.toUser ? 'Client' : 'You') + ' will not receive a discount on the order.</h6>'
        }
        section += creditOrderFinalConfirmationModal(this.numberFilter(this.tvsCreditsToLocalCredits(this.model.creditsToAdd, this.getCurrentCurrencyUsed)),
          (this.formatToCurrency(this.model.priceToAdd, this.currencyForExchange) + " " + this.currencyForExchange.name), this.symbol)
        if (!this.toUser) {
          section += creditOrderFinalConfirmationModalSection2()
        }
        section += '</div>';
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          html: section,
          confirmButtonColor: "#d14343",
          cancelButtonColor: "#000000",
          confirmButtonText: "ORDER",
          cancelButtonText: "CANCEL",
        }).then((value) => {
          if (value.isConfirmed) {
            this.processPlaceOrder().then()
          } else {
            this.disabledButton = false;
          }
        })
      } else {
        this.disabledButton = false;
        this.showAlertNotification("Something is wrong. Please check again", 'error')
      }
    },
    async processPlaceOrder() {
      this.loaderManager(true);
      const postData = {
        price: this.model.price,
        discount: this.model.discount,
        credit: this.model.credit,
        price_id: this.model.price_id,
        to_user_id: this.userClientId,
        amount: this.model.priceToAdd,
        amount_credits: this.model.creditsToAdd,
        emailPurchase: false
      };
      if (this.toUser) {
        postData.to_user_id = this.toUser
      }
      post('credit-order', postData, true).then(async ({data}) => {
        if (data != 'error') {
          if (data.id) {
            this.showAlertNotification(this.$t('credit_order.place_order_success'))
            this.model = {price_id: null, comment: ''};
            this.priceList = this.priceList.map((el, i) => {
              return {...el, selected: false};
            });
            this.$router.push({
              name: 'credit-order-detail',
              params: {id: data.id}
            });
          } else if (data == 200) {
            this.showAlertNotification(this.$t('credit_order.place_order_success'))
            this.model = {price_id: null, comment: ''};
          }
          this.disabledButton = false;
          this.loaderManager(false);
        } else {
          this.disabledButton = false;
          this.loaderManager(false);
          this.showAlertNotification('The transaction could not be completed. Contact your Distributor.', 'info')
        }
      });
    },
    validateData() {
      return (Number(this.model.price) > 0) && (this.model.discount >= 0) && (this.model.discount <= 65) && (this.model.price >= this.model.priceToAdd) && (this.model.credit >= this.model.creditsToAdd)
    },
  },
}
</script>

<style scoped>

</style>
