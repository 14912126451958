<template>
  <b-modal v-model="active" @close="close" class="modal hide" data-backdrop="static" data-keyboard="false">
    <template #modal-header>
      <h6 style="color: #d14343; text-transform: uppercase">Send offer to {{ fullName }}</h6>
    </template>
    <div class="row">
      <div class="col">
        <strong v-if="currentList">Currently the user is assigned to: {{ currentList.name }}</strong>
        <br>
        <label> Select Price List</label>
        <select class="mb-3 form-control" @change="selectPriceList($event.target.value)">
          <option value="">Select</option>
          <option :value="item._id" v-for="(item, index) in priceList"
                  :key="index"> {{ item.name }}
          </option>
        </select>
        <label> Select Price</label>
        <select class="mb-3 form-control" @change="selectPrice($event.target.value)">
          <option value="">Select</option>
          <option :value="item.id" v-for="(item, index) in priceListSecondary"
                  :key="index"> Credits: {{ creditsToLocalCredits(item.credit, currency) }} Discount: {{ item.discount }}% - {{ currencyToUser(item.price, currency) }}
          </option>
        </select>
      </div>
    </div>
    <template #modal-footer>
      <span v-if="showError === true" class="error_field">Not enough balance</span>
      <b-button v-if="showError === false" @click="sendOffer" variant="primary" :disabled="showError === true ">Send
        offer
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {post} from '@/services/api';
import {mapGetters, mapState} from 'vuex';
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: 'SendOfertCredits',
  mixins: [show_alert_mixin],
  mounted() {
    this.priceListSecondary = []
  },
  data: () => ({
    active: false,
    fullName: '',
    email: '',
    model: {
      user_id: null,
      credits: 0,
      price: 0
    },
    showError: false,
    priceSelected: null,
    priceListSecondary: []
  }),
  props: {
    priceConfiguration: {
      default: []
    },
    currency: {
      require: true,
      type: Object
    }
  },
  computed: {
    ...mapState('buySale', ['information']),
    ...mapGetters('geo', ['currencyToUser', 'creditsToLocalCredits']),
    priceList() {
      return this.priceConfiguration.list;
    },
    currentList() {
      return this.priceConfiguration.assigned;
    }
  },
  methods: {
    open(user_id, user_name, user_email) {
      this.fullName = user_name;
      this.email = user_email;
      this.model.user_id = user_id;
      this.active = true;
      if (this.currentList !== null) {
        this.selectPriceList(this.currentList._id);
      }
    },
    close() {
      this.active = false;
    },
    sendOffer() {
      if ((Number(this.information.balance) >= Number(this.model.credits)) && this.model.credits > 0) {
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          title: 'Are you sure?',
          confirmButtonColor: '#d14343',
          cancelButtonColor: '#000000',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        })
          .then(async res => {
            if (res.isConfirmed) {
              this.$store.commit('loaderManager', true);
              post('credit-order/send-offer', this.model, true)
                .then(() => {
                  this.showAlertNotification('The offer has been sent')
                  this.active = false
                })
                .finally(() => this.$store.commit('loaderManager', false));
            }
          });
      }
    },
    validateBalance() {
      this.showError = (Number(this.information.balance) <= Number(this.model.credits));
      return this.showError;
    },
    selectPriceList(id) {
      if (id !== null && id !== "") {
        this.showError = false;
        this.priceSelected = this.priceList.find(element => element._id.toString() === id.toString());
        this.priceListSecondary = this.priceSelected.priceList;
      } else {
        this.priceListSecondary = []
      }
    },
    selectPrice(id) {
      if (id !== null && id !== "") {
        const temp = this.priceListSecondary.find(element => element.id.toString() === id.toString());
        this.model.credits = temp.credit;
        this.model.price = temp.price;
        this.validateBalance();
      }
    }
  }
};
</script>
<style scoped>

</style>
