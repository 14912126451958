import Vue from 'vue';
import {post} from '@/services/api';
import {isEmpty} from '@/utils';
import CreditsView from '@/components/common/CreditsView';
import CurrencyPriceView from '@/components/common/CurrencyPriceView';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import {creditOrderFinalConfirmationModal, creditOrderFinalConfirmationModalSection2} from "@/constants";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default Vue.extend({
  name: 'OrderCredit',
  props: {
    asSubComponent: {
      default: false,
    },
    toUser: {
      default: false,
      type: Number,
    },
    restartValueForm: {
      default: false
    }
  },
  mixins: [show_alert_mixin],
  components: {
    CreditsView,
    CurrencyPriceView
  },
  data: () => ({
    model: {
      price_id: null,
      price: null,
      discount: 0,
      credit: 0,
    },
    disabledButton: false,
    currencyForExchange: null
  }),
  mounted() {
    this.currencyForExchange = this.$store.state.geo.userCurrency;
    if (!this.$store.state.buySale.information.haveParent) {
      this.currencyForExchange = {...this.$store.state.geo.tvsCurrency, locale: this.userCurrency.locale};
    }
    this.getPriceList();
  },
  computed: {
    ...mapGetters('geo', ['currencyToUser', 'creditsToLocalCredits', 'currencyCreditSymbol', 'localCreditsToTvsCredits', 'formatToCurrency', 'getCurrentCurrencyUsed', 'tvsCreditsToLocalCredits', 'numberFilter']),
    ...mapState('buySale', ['information']),
    ...mapState('geo', ['tvsCurrency', 'userCurrency', 'showInLocal']),
    ...mapState('priceList', {
      priceList: state => state.priceListInformation
    }),
    symbol() {
      const code = this.getCurrentCurrencyUsed.code.toLowerCase();
      try {
        return require(`@/assets/images/credits/${code}.svg`);
      } catch (e) {
        return require(`@/assets/images/credits/default.svg`);
      }
    }
  },
  watch: {
    'model.price': function () {
      let reversed = [].concat(this.priceList).reverse();
      this.priceList = this.priceList.map(el => Object.assign(el, {selected: false}));
      this.model.credit = 0;
      this.model.discount = 0;
      const priceConverted = this.localCreditsToTvsCredits(this.model.price, this.currencyForExchange);
      for (let price of reversed) {
        if (priceConverted >= Math.round(price.price)) {
          let factor = parseFloat((price.price / price.credit).toFixed(2));
          this.model.discount = price.discount;
          this.model.credit = this.localCreditsToTvsCredits(Math.round(this.model.price / factor), this.currencyForExchange);
          this.priceList.find(el => el.id === price.id).selected = true;
          break;
        }
      }
      if (this.model.discount === 0) {
        this.model.credit = priceConverted;
      }
      this.$emit("recalculateOrder", this.model)
    },
    information: {
      handler: function () {
      },
      deep: true
    },
    restartValueForm: function (e) {
      if (e) {
        this.clearForm()
      }
    }
  },
  methods: {
    ...mapMutations(['loaderManager', 'userNameManager']),
    ...mapActions('priceList', ['loadInformationPriceList']),
    getPriceList() {
      this.loadInformationPriceList().then();
    },
    async placeOrder() {
      this.disabledButton = true;
      if (!isEmpty(this.model.price) && Number(this.model.price) > 0) {
        let section = '\n' +
          '<div>\n' +
          '<div class="row">' +
          '<div class="text-center col">\n' +
          '<h4 style="color: black">FINAL CONFIRMATION</h4>';
        if (!this.priceList.length) {
          section += '<h6 class="text-danger">' + (this.toUser ? 'Client' : 'You') + ' will not receive a discount on the order.</h6>'
        }
        section += creditOrderFinalConfirmationModal(this.numberFilter(this.tvsCreditsToLocalCredits(this.model.credit, this.getCurrentCurrencyUsed)),
          (this.formatToCurrency(this.model.price, this.currencyForExchange) + " " + this.currencyForExchange.name), this.symbol)
        if (!this.toUser) {
          section += creditOrderFinalConfirmationModalSection2()
        }
        section += '</div>';
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          html: section,
          confirmButtonColor: "#d14343",
          cancelButtonColor: "#000000",
          confirmButtonText: "ORDER",
          cancelButtonText: "CANCEL",
        }).then((value) => {
          if (value.isConfirmed) {
            this.processPlaceOrder().then()
          } else {
            this.clearForm()
          }
        })
      } else {
        this.disabledButton = false;
        this.showAlertNotification(this.$t('credit_order.price_error'), 'error')
      }
    },

    async processPlaceOrder() {
      this.loaderManager(true);
      const postData = {
        price: this.localCreditsToTvsCredits(this.model.price, this.currencyForExchange),
        discount: this.model.discount,
        credit: this.model.credit,
        price_id: this.model.price_id
      };
      if (this.toUser) {
        postData.to_user_id = this.toUser
      }
      post('credit-order', postData, true)
        .then(async ({data}) => {
          if (data != 'error') {
            if (data.id) {
              this.showAlertNotification(this.$t('credit_order.place_order_success'))
              this.model = {
                price_id: null,
                comment: ''
              };
              this.priceList = this.priceList.map((el, i) => {
                return {
                  ...el,
                  selected: false
                };
              });
              this.$router.push({
                name: 'credit-order-detail',
                params: {id: data.id}
              });
            } else if (data == 200) {
              this.showAlertNotification(this.$t('credit_order.place_order_success'))
              this.model = {
                price_id: null,
                comment: ''
              };
            }
            this.disabledButton = false;
            this.loaderManager(false);
          } else {
            this.disabledButton = false;
            this.loaderManager(false);
            this.showAlertNotification('The transaction could not be completed. Contact your Distributor.', 'info')
          }
        });
    },

    clearForm() {
      this.model = {
        price_id: null,
        price: null,
        discount: 0,
        credit: 0,
      }
      this.disabledButton = false
    }
  },
});

