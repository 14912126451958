<template>
  <div class="row d-flex justify-content-center">
    <div class="col-lg-6 col-md-6">
      <b-form-group>
        <label v-if="currencyForExchange !== null"> Purchase price: (<span
          style="font-weight: bold">{{ currencyForExchange.symbol }}</span>)</label>
        <b-form-input class="mb-2" id="input-credits" v-model.number="model.price" type="number" placeholder="Enter total price" required></b-form-input>
        <label>Discount: </label>
        <b-form-input class="mb-2" id="input-discount" v-model.number="model.discount" type="number" placeholder="Enter discount" required></b-form-input>
        <label>Credit amount: </label>
        <credits-view disabled="true" :amount="model.credit"/>
      </b-form-group>
    </div>

  </div>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import CreditsView from "@/components/common/CreditsView";
import CurrencyPriceView from "@/components/common/CurrencyPriceView";

export default {
  name: "ManualCreditsWithInvoice",
  components: {CreditsView, CurrencyPriceView},
  data: () => ({
    model: {
      price_id: null,
      price: null,
      discount: 0,
      credit: 0,
    },
    currencyForExchange: null,
  }),
  computed: {
    ...mapGetters('geo', ['currencyToUser', 'creditsToLocalCredits', 'currencyCreditSymbol', 'localCreditsToTvsCredits',
      'formatToCurrency', 'getCurrentCurrencyUsed', 'tvsCreditsToLocalCredits', 'numberFilter']),
    ...mapState('buySale', ['information']),
    ...mapState('geo', ['tvsCurrency', 'userCurrency', 'showInLocal']),
    symbol() {
      const code = this.getCurrentCurrencyUsed.code.toLowerCase();
      try {
        return require(`@/assets/images/credits/${code}.svg`);
      } catch (e) {
        return require(`@/assets/images/credits/default.svg`);
      }
    },

  },
  watch: {
    'model.credits': function (e) {
      this.recalculate()
    },
    'model.discount': function (e) {
      this.recalculate()
    },
  },

  mounted() {
    this.currencyForExchange = this.$store.state.geo.userCurrency;
    if (!this.$store.state.buySale.information.haveParent) {
      this.currencyForExchange = {...this.$store.state.geo.tvsCurrency, locale: this.userCurrency.locale};
    }
  },
  methods: {
    recalculate() {
      if (this.model.discount === '' || this.model.discount === null) {
        this.model.discount = 0
      }
      const percentage = 1 - (this.model.discount / 100)
      this.model.credit = Number((this.model.price / percentage).toFixed(0))
      this.$emit("recalculateOrder", this.model)
    },
  },
}
</script>

<style scoped>

</style>
